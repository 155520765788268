import { createMuiTheme } from "@material-ui/core"

const theme = createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#0057FF',
      },
      secondary: {
        main: '#607bdd',
      },
      background: {
        paper: 'white',
        paper2: 'white',
        paperChip: '#E8F1F9',
        footer: 'white',
      },
      linkColor: {
        color: 'hsl(255, 5%, 14%)'
      },
      selection: {
        color: '#ccd0d3'
      }
    },
    typography: {
      fontFamily: [
      'Roboto',
    ].join(','),
      h1: {
        color: "#001224",
        fontWeight: 700,
        fontSize: "5.5vw",
        fontFamily: 'Ubuntu',
      },
      h2: {
        color: "#001224",
        fontWeight: 700,
        fontSize: "2.5vw",
        fontFamily: 'Ubuntu',
        '@media (max-width: 1024px)': {
          fontSize: "3vw",
        },
        '@media (max-width:767px)': {
          fontSize: "8vw",
        },
      },
      h3: {
        color: "#001224",
        fontWeight: 700,
        fontSize: "2.2vw",
      },
      h4: {
        color: "#001224",
        fontWeight: '700',
        fontSize: "4vw",
      },
      h5: {
        color: '#001224',
        fontWeight: 600,
        fontSize: "3vw",
        fontStyle: 'normal',
      },
      h6: {
        fontSize: "1vw",
        fontWeight: 600,
        color: "#001224",
      },
      body1: {
        fontSize: "1.1em",
        color: "#001224",
        fontWeight: 400,
        '@media (max-width:767px)': {
          fontSize: "1em",
        },
      },
      body2: {
        fontSize: "1.3em",
        color: "#001224",
        fontWeight: 400,
        '@media (max-width: 1024px)': {
          fontSize: "2.3vw",
        },
        '@media (max-width:767px)': {
          fontSize: "1.3em",
        },
      },
      subtitle1: {
        fontSize: "24px",
        color: "white",
        fontWeight: 400,
        fontFamily: 'Ubuntu',
      },
      subtitle2: {
        color: "#001224",
        fontSize: "18px",
        fontWeight: 400,
        '@media (max-width: 1024px)': {
          fontSize: "1.5vw",
        },
        '@media (max-width:767px)': {
          fontSize: "18px",
        },
      },
    },
    overrides: {
      MuiCard: {
        root: {
          boxShadow: 'none',
          display: 'block',

        },
      },
      MuiCardActionArea: {
        root: {
          backgroundColor: 'white',
        }
      }
    },
    shadows: [
      'none',
      '0px 4px 50px rgba(0, 0, 0, 0.1)',
      '0px 4px 50px rgba(0, 0, 0, 0.1)'
    ]



});

export default theme;
