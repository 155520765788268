import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import lightTheme from './src/templates/lightTheme.js'
// import Layout from './src/components/layout'
import ClientOnly from './src/clientOnly.js'

export default function MuiRootWrapper({ element }) {



  return (
    <ClientOnly>
      <ThemeProvider theme={lightTheme}>
          {element}
      </ThemeProvider>
    </ClientOnly>
  )
}
